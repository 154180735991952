/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';

window.Vue = require("vue").default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


const app = new Vue({
    el: '#app',
});

let editor = null;
if (document.querySelector('#editor')) {
    editor = new Editor({
        el: document.querySelector('#editor'),
        height: '500px',
        initialEditType: 'markdown',
        previewStyle: 'vertical'
    });
}

if (editor && document.querySelector('#template-form')) {
    editor.setMarkdown(document.querySelector('#template').value);

    document.querySelector('#template-form').addEventListener('submit', e => {
        e.preventDefault();
        document.querySelector('#template').value = editor.getMarkdown();
        e.target.submit();
    });
}

if (document.querySelector('#periodsetting-form')) {
    editor.setMarkdown(document.querySelector('#value').value);

    document.querySelector('#periodsetting-form').addEventListener('submit', e => {
        e.preventDefault();
        document.querySelector('#value').value = editor.getMarkdown();
        e.target.submit();
    });
}

window.exportDatatable = function (t, tableName) {
    let text = "\xEF\xBB\xBF";
    let data = t.rows().data().map(function (row) {
        return row.map(function (cell) {
            const res = $('<p>' + cell + '</p>').text();
            return res.replaceAll('"', '').replaceAll("'", '').split(/\s+/).join(' ');
        });

    });
    data.map(row => text += '"' + row.join('";"') + '"\n');


    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', tableName + '-export.csv');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

window.exportToCSV = function (tableName) {
    // Táblázat kiválasztása
    const dataTable = $('.dataTable').DataTable();

    // Csak a szűrt sorokat választjuk ki
    let rowsFiltered = dataTable.rows({search: 'applied'}).nodes().toArray();

    // Fejlécek kiválasztása
    let header = [];
    $('.dataTable thead th').each(function () {
        header.push('"' + $(this).text().trim() + '"');
    });

    // CSV adatok generálása
    let csv = header.join(';') + '\n';
    for (let row of rowsFiltered) {
        let rowArray = [];
        $(row).find('td').each(function () {
            let text = $(this).text().trim();
            text = text.replace(/"/g, '""'); // idézőjelek duplázása
            text = '"' + text.replace(/\n/g, ' ') + '"'; // újsor karakterek eltávolítása vagy cseréje szóközre
            rowArray.push(text);
        });
        csv += rowArray.join(';') + '\n';
    }

    // CSV fájl letöltése
    let blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], {type: 'text/csv;charset=utf-8'});
    let a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'table.csv';
    a.click();
}

window.simplePostForm = function (url, method, params) {
    const form = $('<form></form>');
    form.attr("method", method);
    form.attr("action", url);
    $.each(params, function (key, value) {
        if (typeof value == 'object' || typeof value == 'array') {
            $.each(value, function (subkey, subvalue) {
                const field = $('<input />');
                field.attr("type", "hidden");
                field.attr("name", key + '[]');
                field.attr("value", subvalue);
                form.append(field);
            });
        } else {
            const field = $('<input />');
            field.attr("type", "hidden");
            field.attr("name", key);
            field.attr("value", value);
            form.append(field);
        }
    });
    $(document.body).append(form);
    form.submit();
    return false;
}
