import * as $ from 'jquery';
import 'datatables';

export default (function () {

    $.fn.dataTable.ext.order.intl = function (locales, options) {
        if (window.Intl) {
            var collator = new window.Intl.Collator(locales, options);
            var types = $.fn.dataTable.ext.type;

            delete types.order['string-pre'];
            types.order['string-asc'] = function (a, b) {
                /*
                const x = $('<div>' + a + '</div>').text();
                const y = $('<div>' + b + '</div>').text();
                return collator.compare(x, y);
                */
                return collator.compare(a, b);
            };
            types.order['string-desc'] = function (a, b) {
                /*
                const x = $('<div>' + a + '</div>').text();
                const y = $('<div>' + b + '</div>').text();
                return collator.compare(x, y) * -1;
                */
                return collator.compare(a, b) * -1;
            };
            $.fn.dataTable.ext.type = types;
        }
    };

    $.fn.dataTable.ext.order.intl('hu', {
        sensitivity: 'variant',
    });

    var collator = new window.Intl.Collator('hu', {sensitivity: 'variant'});

    $.extend($.fn.dataTableExt.oSort, {
        "hun-asc": function (a, b) {
            const x = $('<div>' + a + '</div>').text();
            const y = $('<div>' + b + '</div>').text();
            return collator.compare(x, y);
        },
        "hun-desc": function (a, b) {
            const x = $('<div>' + a + '</div>').text();
            const y = $('<div>' + b + '</div>').text();
            return collator.compare(x, y) * -1;
        }

    });

    window.dataTables = [];
    const ro = new ResizeObserver(() => {
        for (let i = 0; i < window.dataTables.length; i++) {
            window.dataTables[i].columns.adjust();
        }
    });

    $('table.dataTable').each(function () {

        let order = 'asc';
        if ($(this).hasClass('dataTable-reverse-order')) {
            order = 'desc';
        }
        const opts = {
            pageLength: 50,
            lengthChange: false,
            columnDefs: [
                {
                    targets: "_all",
                    type: "hun"
                }
            ],
            language: {
                url: '/js/datatable.hu.json'
            },
            ordering: true,
            order: [1, order],
            autoWidth: false,
            responsive: true,
            orderClasses: false,
            stateSave: true,
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: 'csvHtml5',
                    text: 'CSV Export'
                }
            ]
        };

        if ($(this).hasClass('dataTable-noOrdering')) {
            opts.ordering = false;
        }

        const t = $(this).DataTable(opts);

        if ($(this).hasClass('dataTable-resizeObserver')) {
            window.dataTables.push(t);
            ro.observe(this);
        }

        $(this).parent().append('<input type="button" class="btn btn-primary export-datatable" value="CSV Export">');
        $(".export-datatable").on('click', function () {
            let tableName = $('.export-csv').attr('tablename');
            exportToCSV(tableName);
        });

    });

    $('form.submit-once').each( function() {
        $(this).submit( function (e) {
            if ($(this).hasClass('submitted-already')) {
                console.log('submitted already');
                e.preventDefault();
                return;
            }
            $(this).addClass('submitted-already');
            $(this).find(':submit').attr('disabled', 'true');
            console.log('disabled submit operation');
        });
    });

}());
